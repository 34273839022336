<template>
  <div class="dg-table">
    <a-dg-table-columns :columns="columns"></a-dg-table-columns>
    <v-virtual-scroll
      :items="filtered_hotels"
      :item-height="44"
      :height="500"
      class="overflow-x-hidden"
    >
      <template v-slot:default="{ item, index }">
        <a-dg-row-hotel
          :columns="columns"
          :rowData="item"
          :rowIndex="index"
          :kpi="kpi"
        ></a-dg-row-hotel>
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script>
import aDgTableColumns from "@/components/dashboard/aDgTableColumns.vue";
import aDgRowHotel from "@/components/dashboard/aDgRowHotel.vue";
import { mapState } from "vuex";

export default {
  name: "a-dg-table",
  components: {
    aDgTableColumns,
    aDgRowHotel
  },
  props: {
    rows: Array,
    kpi: String,
    search: String
  },
  computed: {
    filtered_hotels() {
      if (this.search) {
        return this.rows.filter(row => {
          const hotel = this.search_keys.find(h => h.id === row.hotel_id);
          return hotel ? hotel.key.includes(this.search.toLowerCase()) : false;
        });
      }
      return this.rows;
    },
    columns() {
      return [
        {
          title: ["otb", "stly", "bud", "ly"],
          align: "left",
          sortable: true,
          value: "otb",
          vs: ["stly", "bud", "ly"]
        },
        // {
        //   title: ["pickup_6", "stly"],
        //   align: "left",
        //   sortable: true,
        //   value: "pickup_6",
        //   vs: ["pickup_stly_6"]
        // },
        // {
        //   title: ["pickup_5", "stly"],
        //   align: "left",
        //   sortable: true,
        //   value: "pickup_5",
        //   vs: ["pickup_stly_5"]
        // },
        {
          title: ["pickup_1", "stly"],
          text: "vs Pickup1 STLY",
          align: "left",
          sortable: true,
          value: "pickup_1",
          vs: ["pickup_stly_1"]
        },
        {
          title: ["pickup_2", "stly"],
          align: "left",
          sortable: true,
          value: "pickup_2",
          vs: ["pickup_stly_2"]
        },
        {
          title: ["pickup_3", "stly"],
          align: "left",
          sortable: true,
          value: "pickup_3",
          vs: ["pickup_stly_3"]
        },
        {
          title: ["pickup_4", "stly"],
          align: "left",
          sortable: true,
          value: "pickup_4",
          vs: ["pickup_stly_4"]
        }
      ];
    },
    search_keys() {
      return this.hotels.map(hotel => ({
        id: hotel.id,
        key: [
          hotel.name,
          this.ancestors(hotel),
          hotel.location && hotel.location.city ? hotel.location.city : ""
        ]
          .join(" ")
          .toLowerCase()
      }));
    },
    ...mapState({
      hotels: state => state.hotel.hotels
    })
  },
  methods: {
    ancestors(hotel) {
      if (hotel.father_id && hotel.father_id !== 1) {
        const father = this.hotels.find(h => h.id === hotel.father_id);
        return father ? father.name + " " + this.ancestors(father) : "";
      }
      return "";
    }
  }
};
</script>
