<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "a-hotelsdot-group-rn-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    count() {
      return this.labels ? Object.keys(this.labels).length : 0;
    },
    margin() {
      return this.count > 10 ? "20px 20px 80px 70px" : "20px 20px 50px 70px";
    },
    angle() {
      return this.count > 10 ? -45 : 0;
    },
    graph_data() {
      return {
        type: "bar",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: this.margin
        },
        plot: {
          tooltip: {
            visible: false
          },
          // stacked: true,
          // stackType: "100%"
          barWidth: "80%",
          borderRadiusTopLeft: 10,
          borderRadiusTopRight: 10
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333",
            angle: this.angle,
            wrapText: true,
            width: 100
          },
          tick: {
            size: 0
          },
          labels: this.labels,
          itemsOverlap: true
        },
        scaleY: {
          lineWidth: 0,
          format: "%vrn",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    graph_series() {
      let series = [];
      for (let serie in this.series) {
        series.push({
          text: serie,
          values: this.series[serie],
          backgroundColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null,
          lineColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null,
          marker: {
            backgroundColor: this.hotelsdot.series_ui[serie]
              ? this.hotelsdot.series_ui[serie].color
              : null
          }
        });
      }
      /* for (let serie in this.series.rn.otb) {
        let sui = this.hotelsdot.series_ui[serie];
        series.push({
          stack: 1,
          text: serie + "rn",
          values: this.series.rn.otb[serie],
          backgroundColor: sui ? sui.color : undefined,
          lineColor: sui ? sui.color : undefined,
          marker: {
            backgroundColor: sui ? sui.color : undefined
          }
        });
      }
      for (let serie in this.series.rn.stly) {
        let sui = this.hotelsdot.series_ui[serie];
        series.push({
          stack: 2,
          text: serie,
          alpha: 0.9,
          values: this.series.rn.stly[serie],
          backgroundColor: sui ? sui.color : undefined,
          lineColor: sui ? sui.color : undefined,
          marker: {
            backgroundColor: sui ? sui.color : undefined
          }
        });
      } */
      return series;
    },
    /* max_value() {
      let max = 0;
      let a = this.series.rn.otb;
      for (let serie in a) {
        let serie_max = a[serie].reduce((a, b) => a + b, 0);
        if (serie_max > max) {
          max = serie_max;
        }
      }
      a = this.series.rn.stly;
      for (let serie in a) {
        let serie_max = a[serie].reduce((a, b) => a + b, 0);
        if (serie_max > max) {
          max = serie_max;
        }
      }
      return parseInt(max + 5);
    }, */
    /* min_value() {
      let min = this.max_value;
      let a = this.series.rn.otb;
      for (let serie in a) {
        let serie_min = a[serie].reduce((a, b) => a + b, 0);
        if (serie_min < min) {
          min = serie_min;
        }
      }
      a = this.series.rn.stly;
      for (let serie in a) {
        let serie_min = a[serie].reduce((a, b) => a + b, 0);
        if (serie_min < min) {
          min = serie_min;
        }
      }

      return parseInt(min - 5 > 0 ? min - 5 : 0);
    }, */
    /* scaleValues() {
      return (
        this.min_value +
        ":" +
        this.max_value +
        ":" +
        parseInt((this.max_value - this.min_value) / 4)
      );
    }, */
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
