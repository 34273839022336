<template>
  <div class="d-flex">
    <!-- {{ series }}
    {{ labels }} -->
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-hotelsdot-pickup-booking-pace",
  components: {},
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: vm => ({}),
  methods: {},
  computed: {
    graph_data() {
      var f1 = Vue.moment().startOf("day");
      var f2 = Vue.moment(
        this.rms.current_pickup_year +
          "-" +
          this.rms.current_pickup_month +
          "-01"
      )
        .endOf("month")
        .startOf("day");
      var forecast_line = 249 - parseInt(f2.diff(f1, "days"));
      var month_area = [249 - parseInt(f2.format("D")), 249];

      let tooltip = this.chart_settings.tooltip;
      tooltip.multiple = true;
      return {
        labels: [
          {
            text: "Hoy",
            "background-color": "#ffffff",
            "border-width": 2,
            "border-color": "#5489d0",
            "font-size": "12px",
            "font-weight": "normal",
            "font-color": "#5489d0",
            padding: "3% 10%",
            "border-radius": "3px",
            "offset-y": -20,
            shadow: false,
            callout: true,
            "callout-height": "5px",
            "callout-width": "10px",
            hook: "node:plot=1;index=" + forecast_line
          }
        ],
        "background-color": "transparent",
        type: "mixed",
        legend: {
          visible: false
        },
        tooltip: {
          visible: false
        },
        plotarea: {
          margin: "25px 45px 45px 35px"
        },
        "scale-x": {
          markers: [
            {
              type: "area",
              range: month_area,
              valueRange: true,
              backgroundColor: "#cacaca",
              alpha: 0.3,
              label: {
                // text: vm.getMonthName(vm.year + "-" + vm.month + "-01"),
                alpha: 1,
                angle: 0,
                fontColor: "#666666",
                fontSize: 10,
                fontStyle: "normal",
                fontWeight: "normal",
                lineStyle: "solid",
                padding: 5,
                textAlign: "left",
                textAlpha: 1,
                width: "100%"
              }
            }
          ],
          label: {
            text: "Días de antelación",
            borderWidth: 0,
            "font-color": "#bbbbbb",
            "font-size": "10px",
            "font-weight": "bold",
            fontWeight: "normal",
            "font-family": "Roboto Condensed",
            padding: "10px"
          },
          labels: this.labels,
          tick: {
            visible: false
          },
          item: {
            "font-color": "#888888",
            "font-size": "10px",
            "font-weight": "bold",
            "font-family": "Roboto Condensed"
          }
        },
        "scale-y": {
          format: "%v%",
          "line-width": "0",
          item: {
            "font-family": "Roboto Condensed",
            "font-color": "#888888",
            "font-size": "11px",
            "font-weight": "bold"
          },
          guide: {
            visible: false
          },
          tick: {
            visible: false
          }
        },
        "crosshair-x": {
          lineWidth: 2,
          "line-color": "#dddddd",
          "line-style": "dashed",
          marker: {
            "background-color": "#ffffff",
            size: 4,
            "border-width": 2,
            "border-color": "#444444",
            opacity: "0.8",
            shadow: 2
          },
          "plot-label": {
            shadow: true,
            "shadow-alpha": 0.3,
            "shadow-distance": "4px",
            "shadow-color": "#666666",
            "border-color": "#999999",
            "border-width": 1,
            "font-family": "Roboto Condensed",
            "background-color": "#ffffff",
            "font-color": "#333333",
            "font-size:": "13px",
            padding: "15px",
            "border-radius": "2px",
            "text-align": "left"
          },
          "scale-label": {
            visible: false,
            "font-color": "#ffffff",
            "background-color": "#707d94",
            padding: "5px 10px",
            "border-radius": "5px"
          }
        }
      };
    },
    graph_series() {
      let series = [];
      series.push({
        type: "line",
        color: "#5489d0",
        plot: {
          aspect: "spline"
        },
        text: "Este año",
        values: this.series.current,
        marker: {
          "border-color": "#5489d0",
          "border-width": 2,
          "background-color": "#ffffff"
        },
        "data-current-dates": this.series.current_dates,
        "guide-label": {
          text:
            "<span style=' color: %color;font-size:13px;'>%data-current-dates:</span> <span style=' font-size:13px; font-weight:bold;'>%v%</span>",
          "thousands-separator": ".",
          "decimals-separator": ","
        },
        "legend-marker": {
          type: "line",
          "border-color": "#5489d0",
          "border-width": 2
        },
        "line-color": "#5489d0",
        "line-width": "3px",
        "background-color": "#5489d0",
        palette: 0
      });
      series.push({
        type: "line",
        color: "#e76f51",
        plot: {
          aspect: "spline"
        },
        text: "Año pasado",
        values: this.series.vs,
        marker: {
          "border-color": "#e76f51",
          "border-width": 2,
          "background-color": "#ffffff"
        },
        "data-current-dates": this.series.vs_dates,
        "guide-label": {
          text:
            "<span style=' color: %color;font-size:13px;'>%data-current-dates:</span> <span style=' font-size:13px; font-weight:bold;'>%v%</span>",
          "thousands-separator": ".",
          "decimals-separator": ",",
          "font-family": "Roboto Condensed"
        },
        "legend-marker": {
          type: "line",
          "border-color": "#e76f51",
          "border-width": 2
        },
        "line-color": "#e76f51",
        "line-width": "3px",
        "background-color": "#e76f51",
        palette: 1
      });

      return series;
    },
    ...mapState({
      rms: state => state.rms,
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    }),
    ...mapGetters({})
  }
};
</script>
