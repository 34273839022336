<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "a-hotelsdot-group-occupancy-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    count() {
      return this.labels ? Object.keys(this.labels).length : 0;
    },
    margin() {
      return this.count > 10 ? "20px 50px 80px 80px" : "20px 50px 50px 80px";
    },
    angle() {
      return this.count > 10 ? -45 : 0;
    },
    graph_data() {
      return {
        type: "area",
        plot: {
          aspect: "spline",
          lineWidth: 1,

          marker: {
            size: 3,
            borderWidth: 1,
            shadow: 0
          }
        },
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: this.margin
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333",
            angle: this.angle,
            wrapText: true,
            width: 100
          },
          tick: {
            size: 0
          },
          labels: this.labels,
          itemsOverlap: true
        },
        scaleY: {
          lineWidth: 0,
          values: "0:100:25",
          format: "%v%",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    graph_series() {
      let series = [];
      for (let serie in this.series) {
        series.push({
          text: serie,
          values: this.series[serie],
          backgroundColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null,
          lineColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null,
          marker: {
            backgroundColor: this.hotelsdot.series_ui[serie]
              ? this.hotelsdot.series_ui[serie].color
              : null
          }
        });
      }
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
