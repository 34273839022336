<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

export default {
  name: "a-hotelsdot-roomrevenue-chart",
  components: {},
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: vm => ({}),
  methods: {},
  computed: {
    graph_data() {
      let tooltip = this.chart_settings.tooltip;
      tooltip.text =
        " <span style='color:%color;text-transform: uppercase;'>%t</span>: <span style='font-weight:600;'>%v€</span>";
      return {
        type: "bar",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 20px 50px 70px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels.map(item => {
            return (
              Vue.moment(item)
                .format("MMMM")
                .substr(0, 1)
                .toUpperCase() +
              Vue.moment(item)
                .format("MMMM")
                .substr(1, 2)
            );
          })
        },
        plot: {
          barWidth: "90%",
          borderRadiusTopLeft: 10,
          borderRadiusTopRight: 10
        },
        scaleY: {
          lineWidth: 0,
          format: "%v€",
          short: true,
          "short-unit": "K",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    graph_series() {
      let series = [];
      for (let serie in this.series) {
        let sui = this.hotelsdot.series_ui[serie];
        series.push({
          text: serie,
          values: this.series[serie],
          backgroundColor: sui ? sui.color : "",
          lineColor: sui ? sui.color : "",
          marker: {
            backgroundColor: sui ? sui.color : ""
          }
        });
      }
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    }),
    ...mapGetters({})
  }
};
</script>
