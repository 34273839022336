<template>
  <v-row class="row-hotel mx-0">
    <v-col class="hotel-wrapper pa-0">
      <div
        class="hotel-label d-flex align-center"
        @click.prevent="setCurrentHotel"
        style="cursor:pointer;"
      >
        <v-avatar :color="hotel.settings.color" class="mt-n1" size="16">
          <span class="white--text" style="font-size:9px;font-weight: 400;">
            {{ hotel.settings.stars }}
          </span>
        </v-avatar>
        <span>{{ hotel.name }}</span>
      </div>
    </v-col>
    <v-col class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col
          v-for="(col, index) of columns"
          :key="index"
          :class="classCol(col)"
          @mouseover="kpi_hover = col.value"
          @mouseleave="kpi_hover = ''"
        >
          <a-kpi-columns
            :kpi="kpi"
            :value="rowData[col.value]"
            :vs_values="vs_values(col)"
            :vs_periods="col.vs"
            x_small
          ></a-kpi-columns>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import aKpiColumns from "@/components/rms_ui/aKpiColumns.vue";

export default {
  name: "a-dg-row-hotel",
  components: {
    aKpiColumns
  },
  props: {
    columns: Array,
    rowData: Object,
    rowIndex: Number,
    kpi: String
  },
  computed: {
    hotel() {
      return this.getHotelById(this.rowData.hotel_id);
    },
    kpi_hover: {
      get() {
        return this.$store.state.rms.hotels_kpi_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsHotelsKpiHover", value);
      }
    },
    ...mapGetters({ getHotelById: "getHotelById" })
  },
  methods: {
    setCurrentHotel() {
      this.$store.dispatch("setCurrentHotel", this.hotel);
    },
    vs_values(col) {
      return col.vs.map(v => this.rowData[v]);
    },
    classCol(col) {
      let hide = false;
      let clas = "pa-0 kpi-cell kpi-cell-row align-center justify-center";
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (col.value == "pickup_1" || col.value == "pickup_2") {
          hide = true;
        }
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        if (col.value == "pickup_3") {
          hide = true;
        }
      }
      if (this.$vuetify.breakpoint.xs) {
        if (col.value == "pickup_4") {
          hide = true;
        }
      }
      if (hide) {
        clas += " d-none";
      } else {
        clas += " d-flex";
      }
      return clas;
    }
  }
};
</script>
<style lang="scss" scoped>
.row-hotel {
  &:hover {
    .hotel-wrapper {
      .hotel-label {
        border-right: 3px solid #2d6ece;
      }
    }
  }
  .hotel-wrapper {
    min-width: 200px;
    max-width: 200px;
    .hotel-label {
      border-right: 3px solid #ffffff;
      border-bottom: $borderType $borderColor;
      align-items: center;
      min-height: $minHeightColRow;
      gap: 5px;
      padding-left: 15px;
      font-size: 0.8rem;
      font-weight: 700;
      color: #555;
      width: 100%;
    }
  }
  .kpi-cell {
    height: $minHeightColRow;
    text-align: center;
    border-bottom: $borderType $borderColor;
    border-left: $borderType $borderColorV;
    border-right: $borderType $borderColorV;
    line-height: 20px;
    font-size: 14px;
    width: 100%;

    &:hover {
      background-color: #f5fdff;
    }

    &.kpi-cell-row {
      span {
        &:first-child {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #555;
          margin-left: 5px;
        }
      }
    }

    .kpi-wrapper {
      &.periods {
        padding-bottom: 0;
      }
    }
  }
}
</style>
