<template>
  <!-- RATE DATE TO COMPARE -->
  <div id="dashboard_rms_hotelsdot_app">
    <v-row class="mt-n4">
      <v-col cols="12" class="pt-0">
        <a-rms-dashboard-resume
          ref="rms_kpis_monthly_graph"
        ></a-rms-dashboard-resume>
      </v-col>
      <v-col cols="12" class="pt-0">
        <a-rms-dashboard-month
          ref="rms_kpis_month_graph"
        ></a-rms-dashboard-month>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import aRmsDashboardResume from "@/components/rms/hotelsdot/aRmsDashboardResume";
import aRmsDashboardMonth from "@/components/rms/hotelsdot/aRmsDashboardMonth";

export default {
  name: "dashboard-rms-app",
  components: {
    aRmsDashboardResume,
    aRmsDashboardMonth
  },
  data: vm => ({
    subscribed_modules: [
      "rms_kpis_monthly_graph",
      "rms_kpis_month_graph",
      "rms_kpis_pickup_month_graph1",
      "rms_kpis_pickup_month_graph2",
      "rms_segments_period_graph"
    ]
  }),
  watch: {
    current_hotel() {}
  },
  methods: {
    ...mapActions([])
  },
  mounted() {},
  computed: {
    ...mapState({}),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style scoped></style>
