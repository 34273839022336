<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
var numeral = require("numeral");

export default {
  name: "a-hotelsdot-pickup-segments-chart",
  components: {},
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: vm => ({}),
  methods: {
    isEmpty(values) {
      return values.reduce((partialSum, a) => partialSum + a, 0) === 0;
    }
  },
  computed: {
    graph_data() {
      let tooltip = this.chart_settings.tooltip;
      return {
        type: "bar",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 20px 50px 70px"
        },
        plot: {
          tooltip: {
            visible: false
          },
          stacked: true,
          // stackType: "100%"
          barWidth: "80%"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels
        },
        scaleY: {
          lineWidth: 0,
          // values: this.scaleValues,
          format: "%vrn",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    graph_series() {
      let series = [];
      if (this.series.rn) {
        for (let serie in this.series.rn.otb) {
          let sui = this.hotelsdot.series_ui[serie];
          if (!this.isEmpty(this.series.rn.otb[serie])) {
            series.push({
              stack: 1,
              text: serie + "rn",
              values: this.series.rn.otb[serie],
              backgroundColor: sui ? sui.color : undefined,
              lineColor: sui ? sui.color : undefined,
              marker: {
                backgroundColor: sui ? sui.color : undefined
              }
            });
          }
        }
        series[series.length - 1]["borderRadiusTopLeft"] = 10;
        series[series.length - 1]["borderRadiusTopRight"] = 10;
        for (let serie in this.series.rn.stly) {
          let sui = this.hotelsdot.series_ui[serie];
          if (!this.isEmpty(this.series.rn.stly[serie])) {
            series.push({
              stack: 2,
              text: serie,
              alpha: 0.9,
              values: this.series.rn.stly[serie],
              backgroundColor: sui ? sui.color : undefined,
              lineColor: sui ? sui.color : undefined,
              marker: {
                backgroundColor: sui ? sui.color : undefined
              }
            });
          }
        }
        series[series.length - 1]["borderRadiusTopLeft"] = 10;
        series[series.length - 1]["borderRadiusTopRight"] = 10;
      }
      return series;
    },
    max_value() {
      let max = 0;
      let a = this.series.rn.otb;
      for (let serie in a) {
        let serie_max = a[serie].reduce((a, b) => a + b, 0);
        if (serie_max > max) {
          max = serie_max;
        }
      }
      a = this.series.rn.stly;
      for (let serie in a) {
        let serie_max = a[serie].reduce((a, b) => a + b, 0);
        if (serie_max > max) {
          max = serie_max;
        }
      }
      return parseInt(max + 5);
    },
    min_value() {
      let min = this.max_value;
      let a = this.series.rn.otb;
      for (let serie in a) {
        let serie_min = a[serie].reduce((a, b) => a + b, 0);
        if (serie_min < min) {
          min = serie_min;
        }
      }
      a = this.series.rn.stly;
      for (let serie in a) {
        let serie_min = a[serie].reduce((a, b) => a + b, 0);
        if (serie_min < min) {
          min = serie_min;
        }
      }

      return parseInt(min - 5 > 0 ? min - 5 : 0);
    },
    scaleValues() {
      return (
        this.min_value +
        ":" +
        this.max_value +
        ":" +
        parseInt((this.max_value - this.min_value) / 4)
      );
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    }),
    ...mapGetters({})
  }
};
</script>
