<template>
  <div id="dashboard_rms_hotelsdot_group_app">
    <v-tabs
      background-color="transparent"
      slider-size="2"
      v-model="tab"
      dark
      grow
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        {{ $t("room_revenue") }}
      </v-tab>
      <v-tab>
        {{ $t("adr") }}
      </v-tab>
      <v-tab>
        {{ $t("occupancy") }}
      </v-tab>
      <v-tab>
        {{ $t("rn") }}
      </v-tab>
    </v-tabs>
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-toolbar>
          <v-row class="py-0">
            <v-col cols="8" class="pb-0"> </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field
                class="right-align mt-4"
                v-model="search"
                rounded
                outlined
                dense
                clearable
                solo
                flat
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('search_hotel_advanced')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item class="">
            <a-hotelsdot-group-room-revenue-chart
              :series="filtered_graph.kpis.room_revenue"
              :labels="filtered_graph.labels"
              height="300px"
            ></a-hotelsdot-group-room-revenue-chart>
            <div class="mt-5"></div>
            <a-dg-table
              :rows="hotels_kpis_period.room_revenue"
              kpi="room_revenue"
              :search="search"
            ></a-dg-table>
          </v-tab-item>
          <v-tab-item class="pt-10">
            <a-hotelsdot-group-adr-chart
              :series="filtered_graph.kpis.adr"
              :labels="filtered_graph.labels"
              height="300px"
            ></a-hotelsdot-group-adr-chart>
            <div class="mt-5"></div>
            <a-dg-table
              :rows="hotels_kpis_period.adr"
              kpi="adr"
              :search="search"
            ></a-dg-table
          ></v-tab-item>
          <v-tab-item class="pt-10">
            <a-hotelsdot-group-occupancy-chart
              :series="filtered_graph.kpis.occupancy"
              :labels="filtered_graph.labels"
              height="300px"
            ></a-hotelsdot-group-occupancy-chart>
            <div class="mt-5"></div>
            <a-dg-table
              :rows="hotels_kpis_period.occupancy"
              kpi="occupancy"
              :search="search"
            ></a-dg-table
          ></v-tab-item>
          <v-tab-item class="pt-10">
            <a-hotelsdot-group-rn-chart
              :series="filtered_graph.kpis.rn"
              :labels="filtered_graph.labels"
              height="300px"
            ></a-hotelsdot-group-rn-chart>
            <div class="mt-5"></div>
            <a-dg-table
              :rows="hotels_kpis_period.rn"
              kpi="rn"
              :search="search"
            ></a-dg-table
          ></v-tab-item>
        </v-tabs-items>
      </template>
      <template v-slot:loading>
        <a-card>
          <v-row class="pt-5">
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col cols="12">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </v-col>
          </v-row>
        </a-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aCard from "@/components/aCard.vue";
import aDgTable from "@/components/dashboard/aDgTable.vue";
import aHotelsdotGroupRoomRevenueChart from "@/components/chart/group/aHotelsdotGroupRoomRevenueChart.vue";
import aHotelsdotGroupAdrChart from "@/components/chart/group/aHotelsdotGroupAdrChart.vue";
import aHotelsdotGroupOccupancyChart from "@/components/chart/group/aHotelsdotGroupOccupancyChart.vue";
import aHotelsdotGroupRnChart from "@/components/chart/group/aHotelsdotGroupRnChart.vue";

export default {
  name: "dashboard-rms-group-app",
  components: {
    aBaseComponent,
    aCard,
    aDgTable,
    aHotelsdotGroupRoomRevenueChart,
    aHotelsdotGroupAdrChart,
    aHotelsdotGroupOccupancyChart,
    aHotelsdotGroupRnChart
  },
  props: {
    period: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    tab: 0,
    subscribed_modules: [
      "rms_hotels_kpis_period",
      "rms_hotels_kpis_period_graph"
    ]
  }),
  methods: {
    rows(kpi) {
      return this.tableData ? this.tableData[kpi] : [];
    }
  },
  computed: {
    search: {
      get() {
        return this.$store.state.rms.hotels_search_query;
      },
      set(value) {
        this.$store.dispatch("setRmsHotelsSearchQuery", value);
      }
    },
    ...mapState({
      hotels: state => state.hotel.hotels,
      hotels_kpis_period: state => state.rms.hotels_kpis_period,
      hotels_kpis_period_graph: state => state.rms.hotels_kpis_period_graph
    }),
    ...mapGetters({
      filtered_graph: "rms_filtered_hotels_kpis_period_graph"
    })
  }
};
</script>

<style lang="scss">
#dashboard-group-app {
  margin-top: 13px;
}
</style>
