import { render, staticRenderFns } from "./aDgTable.vue?vue&type=template&id=73b6da38"
import script from "./aDgTable.vue?vue&type=script&lang=js"
export * from "./aDgTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VVirtualScroll})
