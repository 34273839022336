<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import * as Utils from "@/utils";
var numeral = require("numeral");

export default {
  name: "a-hotelsdot-segments-chart",
  components: {},
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: vm => ({}),
  methods: {
    isEmpty(values) {
      return values.reduce((partialSum, a) => partialSum + a, 0) === 0;
    }
  },
  computed: {
    graph_data() {
      let tooltip = this.chart_settings.tooltip;
      tooltip.text =
        " <span style='color:%color;text-transform: uppercase;'>%t</span>: <span style='font-weight:600;'>%v€</span>";
      return {
        type: "bar",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 20px 50px 70px"
        },

        plot: {
          tooltip: {
            visible: false
          },
          stacked: true,
          stackType: "100%",
          barWidth: "80%"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels
        },
        scaleY: {
          lineWidth: 0,
          values: "0:100:25",
          format: "%v%",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    graph_series() {
      let series = [];
      for (let serie in this.series) {
        let color = Utils.getDimensionColor(this.segments, serie);
        if (!this.isEmpty(this.series[serie])) {
          series.push({
            text: serie,
            values: this.series[serie],
            backgroundColor: color ? color : undefined,
            lineColor: color ? color : undefined,
            marker: {
              backgroundColor: color ? color : undefined
            }
          });
        }
      }
      series[series.length - 1]["borderRadiusTopLeft"] = 10;
      series[series.length - 1]["borderRadiusTopRight"] = 10;
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot,
      segments: state => state.segmentschannels.segments
    }),
    ...mapGetters({})
  }
};
</script>
