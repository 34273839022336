<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-hotelsdot-pickup-roomrevenue-revpar-chart",
  components: {},
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    graph_data() {
      let tooltip = this.chart_settings.tooltip;
      tooltip.multiple = true;
      return {
        type: "mixed",

        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 20px 50px 50px"
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineColor: "#cacaca",
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels.slice().reverse()
        },
        scaleY: {
          lineWidth: 0,
          format: "%v€",
          short: true,
          "short-unit": "K",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        scaleY2: {
          lineWidth: 0,
          format: "%v€",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        crosshairX: this.chart_settings.crosshair_settings
      };
    },
    graph_series() {
      let series = [];
      for (let serie in this.series.room_revenue) {
        let values = this.series.room_revenue[serie].slice().reverse();
        series.push({
          type: "bar",
          lineWidth: 1,

          rules: [
            {
              rule: `%v < 0`,
              borderRadiusBottomLeft: 10,
              borderRadiusBottomRight: 10
            },
            {
              rule: `%v >= 0`,
              borderRadiusTopLeft: 10,
              borderRadiusTopRight: 10
            }
          ],
          marker: {
            size: 3,
            borderWidth: 1,
            shadow: 0,
            backgroundColor: this.hotelsdot.series_ui[serie]
              ? this.hotelsdot.series_ui[serie].color
              : null
          },
          scales: "scale-x,scale-y",
          text: serie,
          values: values,
          backgroundColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null,
          lineColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null
        });
      }
      for (let serie in this.series.revpar) {
        let values = this.series.revpar[serie].slice().reverse();
        series.push({
          type: "line",
          text: serie,
          scales: "scale-x,scale-y-2",
          values: values,
          aspect: "spline",
          lineWidth: 3,
          marker: {
            backgroundColor: this.hotelsdot.series_ui[serie]
              ? this.hotelsdot.series_ui[serie].color
              : null,
            size: 5,
            borderWidth: 1,
            opacity: "0.3",
            shadow: 1
          },
          backgroundColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null,
          lineColor: this.hotelsdot.series_ui[serie]
            ? this.hotelsdot.series_ui[serie].color
            : null
        });
      }
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    }),
    ...mapGetters({})
  }
};
</script>
