<template>
  <a-page :image_source="image_source" :image_height="image_height">
    <template v-slot:header_options>
      <a-rms-range-selector
        :event_period="period"
        ref="a-rms-range-selector"
        @updatePeriod="updatePeriod"
        v-if="isGroup"
      ></a-rms-range-selector>
      <a-rms-import-dates
        ref="rms_import_dates"
        v-if="!isGroup"
      ></a-rms-import-dates>
    </template>
    <template v-if="current_hotel">
      <dashboard-rms-group-app
        v-if="isGroup"
        ref="dashboard_rms_hotelsdot_group"
        :period="period"
      ></dashboard-rms-group-app>
      <dashboard-rms-app
        v-else
        ref="dashboard_rms_hotelsdot"
      ></dashboard-rms-app>
    </template>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import dashboardRmsApp from "@/components/app/hotelsdot/dashboardRmsApp";
import dashboardRmsGroupApp from "@/components/app/hotelsdot/dashboardRmsGroupApp";
import aRmsRangeSelector from "@/components/rms/aRmsRangeSelector.vue";
import ARmsImportDates from "@/components/rms/aRmsImportDates.vue";

export default {
  components: {
    ARmsImportDates,
    aPage,
    dashboardRmsApp,
    dashboardRmsGroupApp,
    aRmsRangeSelector
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    period: null
  }),
  methods: {
    updatePeriod(period) {
      this.period = period;
      this.$store.dispatch("setRmsHotelsKpisPeriod", period);
    }
  },
  created() {
    let firstDay = Vue.moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    let lastDay = Vue.moment()
      .endOf("month")
      .format("YYYY-MM-DD");
    this.period = [firstDay, lastDay];
    this.$store.dispatch("setRmsHotelsKpisPeriod", [firstDay, lastDay]);
    this.$nextTick(() => {
      this.$store.dispatch("fetchRateDayRates");
    });
  },
  watch: {},
  computed: {
    image_height() {
      return this.isGroup ? 470 : 830;
    },
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    ...mapState({
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  updated() {
    this.$nextTick(() => {
      this.$store.dispatch("setReloadSubscribedModules");
    });
  }
};
</script>
