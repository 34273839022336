<template>
  <a-base-component :subscribed_modules="subscribed_modules">
    <template v-slot:default>
      <div class="d-flex justify-center">
        <v-btn
          icon
          color="white"
          large
          class="my-6 title"
          @click="monthBefore"
          :disabled="monthIndex <= 0"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              color="white"
              rounded
              v-bind="attrs"
              v-on="on"
              large
              class="my-6 title"
            >
              {{ selected_month.month_label }}
              {{ selected_month.year_label }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="selected_month">
              <v-list-item
                v-for="(item, index) in rms_available_months"
                :key="index"
                :value="item"
              >
                <v-list-item-title class="text-center"
                  >{{ item.month_label }}
                  {{ item.year_label }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn
          icon
          color="white"
          large
          class="my-6 title"
          @click="monthAfter"
          :disabled="monthIndex >= 11"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col cols="4" class="text-center">
          <a-card no_title>
            <v-card-text>
              <a-label class="mb-0">
                {{ $t("monthly_revenue") }}
              </a-label>
              <div class="mb-3">
                <a-period-compare
                  :periods="['otb', 'stly', 'bud', 'ly']"
                ></a-period-compare>
              </div>
              <a-kpi
                kpi="room_revenue"
                :value="rms.month_kpis.otb.room_revenue"
                :vs_values="[
                  rms.month_kpis.stly.room_revenue,
                  rms.month_kpis.bud.room_revenue,
                  rms.month_kpis.ly.room_revenue
                ]"
                :vs_periods="['stly', 'bud', 'ly']"
                large
              ></a-kpi>
            </v-card-text>
          </a-card>
        </v-col>
        <v-col cols="4" class="text-center">
          <a-card no_title>
            <v-card-text>
              <a-label class="mb-0">
                {{ $t("revpar_month") }}
              </a-label>
              <div class="mb-3">
                <a-period-compare
                  :periods="['otb', 'stly', 'bud', 'ly']"
                ></a-period-compare>
              </div>
              <a-kpi
                kpi="room_revenue"
                :value="rms.month_kpis.otb.revpar"
                :vs_values="[
                  rms.month_kpis.stly.revpar,
                  rms.month_kpis.bud.revpar,
                  rms.month_kpis.ly.revpar
                ]"
                :vs_periods="['stly', 'bud', 'ly']"
                large
              ></a-kpi>
            </v-card-text>
          </a-card>
        </v-col>
        <v-col cols="4" class="text-center">
          <a-card no_title>
            <v-card-text>
              <a-label class="mb-0">
                {{ $t("intermediation") }}
              </a-label>
              <div class="mb-3">
                <a-period-compare
                  :periods="['otb', 'stly', 'ly']"
                ></a-period-compare>
              </div>
              <a-kpi
                kpi="intermediation"
                :value="rms.month_kpis.otb.intermediation"
                :vs_values="[
                  rms.month_kpis.stly.intermediation,
                  rms.month_kpis.ly.intermediation
                ]"
                :vs_periods="['stly', 'ly']"
                large
              ></a-kpi>
            </v-card-text>
          </a-card>
        </v-col>
      </v-row>
      <a-card :title="$t(title)" class="pb-2">
        <v-row>
          <v-col cols="4" class="py-0 text-center">
            <a-label>
              {{ $t("occupancy") }} <small>+</small> {{ $t("adr") }}
            </a-label>
            <a-period-compare :periods="['otb', 'stly']"></a-period-compare>
            <a-hotelsdot-pickup-occupation-adr-chart
              height="300px"
              :series="rms.kpis_pickup_month_graph1.kpis"
              :labels="rms.kpis_pickup_month_graph1.labels"
            ></a-hotelsdot-pickup-occupation-adr-chart>
          </v-col>
          <v-col cols="4" class="py-0 text-center">
            <a-label>
              {{ $t("room_revenue") }} <small>+</small> {{ $t("revpar") }}
            </a-label>
            <a-period-compare :periods="['otb', 'stly']"></a-period-compare>
            <a-hotelsdot-pickup-roomrevenue-revpar-chart
              height="300px"
              :series="rms.kpis_pickup_month_graph2.kpis"
              :labels="rms.kpis_pickup_month_graph2.labels"
            ></a-hotelsdot-pickup-roomrevenue-revpar-chart>
          </v-col>
          <v-col cols="4" class="py-0 text-center">
            <a-label> {{ $t("booking_pace") }}</a-label>
            <a-period-compare :periods="['otb', 'stly']"></a-period-compare>
            <a-hotelsdot-pickup-booking-pace
              height="300px"
              :series="rms.booking_pace"
              :labels="rms.booking_pace.periods"
            ></a-hotelsdot-pickup-booking-pace>
          </v-col>
        </v-row>
      </a-card>
    </template>
    <template v-slot:loading>
      <div class="d-flex justify-center">
        <v-btn icon color="white" large class="my-6 title">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn text color="white" rounded large class="my-6 title">
          <v-skeleton-loader class="mt-2" type="text"></v-skeleton-loader>
        </v-btn>
        <v-btn icon color="white" large class="my-6 title">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col cols="4" class=" text-center" v-for="i in 3" :key="i">
          <a-card no_title>
            <v-card-text>
              <v-skeleton-loader
                class="mt-2"
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-card-text>
          </a-card>
        </v-col>
      </v-row>
      <a-card :title="$t(title)" class="pb-2">
        <v-row>
          <v-col cols="4" class="px-8" v-for="i in 3" :key="i">
            <v-skeleton-loader
              class="mb-6"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </a-card>
    </template>
  </a-base-component>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent";
import aHotelsdotPickupOccupationAdrChart from "@/components/chart/hotelsdot/aPickupOccupationAdrChart";
import aHotelsdotPickupRoomrevenueRevparChart from "@/components/chart/hotelsdot/aPickupRoomrevenueRevparChart";
import aHotelsdotPickupSegmentsChart from "@/components/chart/hotelsdot/aPickupSegmentsChart";
import aHotelsdotPickupBookingPace from "@/components/chart/hotelsdot/aPickupBookingPace";

import aLabel from "@/components/rms_ui/aLabel";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare";
import aKpi from "@/components/rms_ui/aKpi";

var numeral = require("numeral");

export default {
  name: "a-rms-dashboard-month",
  components: {
    aCard,
    aBaseComponent,
    aLabel,
    aPeriodCompare,
    aKpi,
    aHotelsdotPickupOccupationAdrChart,
    aHotelsdotPickupRoomrevenueRevparChart,
    aHotelsdotPickupBookingPace
    // aHotelsdotPickupSegmentsChart
  },
  data: vm => ({
    title: "month_pickup",
    subscribed_modules: [
      "rms_kpis_month_graph",
      "rms_kpis_pickup_month_graph1",
      "rms_kpis_pickup_month_graph2",
      "rms_month_kpis",
      "rms_ytd_month_kpis",
      "rms_booking_pace"
    ],
    selected_month: 0
  }),
  methods: {
    monthBefore() {
      this.selected_month = this.rms_available_months[this.monthIndex - 1];
    },
    monthAfter() {
      this.selected_month = this.rms_available_months[this.monthIndex + 1];
    }
    // getBookingPace() {
    //   this.$store.dispatch("fetchRmsBookingPace");
    // }
  },
  mounted() {
    this.selected_month = this.rms_available_months[0];
    // console.log('getBookingPace from mounted')
    // this.getBookingPace();
  },
  watch: {
    selected_month() {
      this.$store.dispatch("setCurrentPickupPeriod", this.selected_month);
      // console.log('getBookingPace from watch')
      // this.getBookingPace();
    }
  },
  computed: {
    monthIndex() {
      let index = this.rms_available_months.findIndex(
        item =>
          item.month === this.selected_month.month &&
          item.year === this.selected_month.year
      );
      return index;
    },
    ...mapState({
      rms: state => state.rms,
      hotelsdot: state => state.hotelsdot
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      rms_available_months: "rms_available_months"
    })
  }
};
</script>
<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
</style>
