<template>
  <div>
    <v-row>
      <v-col cols="6" class="text-center">
        <a-card no_title>
          <a-base-component :subscribed_modules="subscribed_modules">
            <template v-slot:default>
              <v-row>
                <v-col cols="7" class=" pb-0 text-left d-flex align-center">
                  <v-icon size="90" class="mr-2" color="grey lighten-3">
                    mdi-calendar-month-outline
                  </v-icon>
                  <div>
                    <a-label class="mb-0 text-left">
                      {{ $t("annual_revenue_to_date") }}
                    </a-label>
                    <a-period-compare
                      :periods="['otb', 'stly', 'stlyn']"
                    ></a-period-compare>
                  </div>
                </v-col>
                <v-col
                  cols="5"
                  class="pb-0 text-right d-flex align-center justify-end "
                >
                  <a-kpi
                    kpi="room_revenue"
                    :value="rms.annual_kpis.otb.room_revenue"
                    :vs_values="[
                      rms.annual_kpis.stly.room_revenue,
                      rms.annual_kpis.stlyn.room_revenue
                    ]"
                    :vs_periods="['stly', 'stlyn']"
                    right
                    x_large
                    vertical
                  >
                  </a-kpi>
                </v-col>
              </v-row>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                class="mt-2"
                type="list-item-avatar-two-line"
              ></v-skeleton-loader
            ></template>
          </a-base-component>
        </a-card>
      </v-col>
      <v-col cols="6" class="text-center">
        <a-card no_title>
          <a-base-component :subscribed_modules="subscribed_modules">
            <template v-slot:default>
              <v-row>
                <v-col cols="7" class=" pb-0 text-left d-flex align-center">
                  <v-icon size="90" class="mr-2" color="grey lighten-3">
                    mdi-calendar-week-outline
                  </v-icon>
                  <div>
                    <a-label class="mb-0 text-left">
                      {{ $t("annual_revenue_to_today") }}
                    </a-label>
                    <a-period-compare
                      :periods="['otb', 'bud', 'lyn']"
                    ></a-period-compare>
                  </div>
                </v-col>
                <v-col
                  cols="5"
                  class="pb-0 text-right  d-flex align-center justify-end"
                >
                  <a-kpi
                    kpi="room_revenue"
                    :value="rms.ytd_annual_kpis.otb.room_revenue"
                    :vs_values="[
                      rms.ytd_annual_kpis.bud.room_revenue,
                      rms.ytd_annual_kpis.lyn.room_revenue
                    ]"
                    :vs_periods="['bud', 'lyn']"
                    vertical
                    right
                    x_large
                  ></a-kpi>
                </v-col>
              </v-row>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                class="mt-2"
                type="list-item-avatar-two-line"
              ></v-skeleton-loader
            ></template>
          </a-base-component>
        </a-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="text-center">
        <a-card :title="$t('next_months_evolution')" class="pb-2">
          <a-base-component :subscribed_modules="subscribed_modules">
            <template v-slot:default>
              <v-row>
                <v-col cols="4" class="py-0 text-center">
                  <a-label> {{ $t("occupancy") }} <small>%</small> </a-label>
                  <a-hotelsdot-occupation-chart
                    height="300px"
                    :series="rms.kpis_monthly_graph.kpis.occupancy"
                    :labels="rms.kpis_monthly_graph.labels"
                  ></a-hotelsdot-occupation-chart>
                </v-col>
                <v-col cols="4" class="py-0 text-center">
                  <a-label> {{ $t("adr") }}</a-label>
                  <a-hotelsdot-adr-chart
                    height="300px"
                    :series="rms.kpis_monthly_graph.kpis.adr"
                    :labels="rms.kpis_monthly_graph.labels"
                  ></a-hotelsdot-adr-chart>
                </v-col>
                <v-col cols="4" class="py-0 text-center">
                  <a-label> {{ $t("room_revenue") }}</a-label>
                  <a-hotelsdot-roomrevenue-chart
                    height="300px"
                    :series="rms.kpis_monthly_graph.kpis.room_revenue"
                    :labels="rms.kpis_monthly_graph.labels"
                  ></a-hotelsdot-roomrevenue-chart>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-2 pb-0">
                  <div
                    class="grey lighten-4  d-flex justify-center align-center rounded-pill py-1"
                  >
                    <a-period
                      v-for="(serie, index) in vs_items"
                      :key="index"
                      :period="serie"
                      class="ml-1"
                    ></a-period>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader class="mt-2" type="text"></v-skeleton-loader>
              <v-row>
                <v-col cols="3" class="px-8">
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col cols="3" class="px-8">
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col cols="3" class="px-8">
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
                <v-col cols="3" class="px-8">
                  <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-col>
              </v-row>
            </template>
          </a-base-component>
        </a-card>
      </v-col>
      <v-col cols="4" class="text-center">
        <a-card :title="null" class="pb-2">
          <a-base-component :subscribed_modules="subscribed_modules">
            <a-label> {{ $t("annual_aggregate_segments") }}</a-label>
            <a-hotelsdot-segments-chart
              v-if="rms.segments_period_graph"
              height="300px"
              :series="rms.segments_period_graph.segments"
              :labels="rms.segments_period_graph.labels"
            ></a-hotelsdot-segments-chart>
            <v-row>
              <v-col
                cols="12"
                class="pt-2 pb-0 d-flex justify-center align-center"
              >
                <div
                  class="grey lighten-4  d-flex justify-center align-center rounded-pill py-1 px-2"
                >
                  <template v-if="rms.segments_period_graph">
                    <v-chip
                      v-for="(serie, index) in Object.keys(
                        rms.segments_period_graph.segments
                      )"
                      :key="index"
                      :color="getDimensionColor(serie)"
                      class="ml-1 mb-1 text-uppercase"
                      x-small
                      outlined
                      background-color="white"
                      style="background-color: white !important;"
                    >
                      {{ serie }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
            </v-row>
          </a-base-component>
        </a-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aCard from "@/components/aCard";
import aBaseComponent from "@/components/aBaseComponent";
import aHotelsdotOccupationChart from "@/components/chart/hotelsdot/aOccupationChart";
import aHotelsdotAdrChart from "@/components/chart/hotelsdot/aAdrChart";
import aHotelsdotRoomrevenueChart from "@/components/chart/hotelsdot/aRoomrevenueChart";
import aHotelsdotSegmentsChart from "@/components/chart/hotelsdot/aSegmentsChart";
import aLabel from "@/components/rms_ui/aLabel";
import aPeriod from "@/components/rms_ui/aPeriod";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare";
import aKpi from "@/components/rms_ui/aKpi";
import * as Utils from "@/utils";
var numeral = require("numeral");

export default {
  name: "a-rms-dashboard-resume",
  components: {
    aCard,
    aBaseComponent,
    aHotelsdotOccupationChart,
    aHotelsdotAdrChart,
    aHotelsdotRoomrevenueChart,
    aHotelsdotSegmentsChart,
    aLabel,
    aPeriod,
    aPeriodCompare,
    aKpi
  },
  data: vm => ({
    subscribed_modules: [
      "rms_kpis_monthly_graph",
      "rms_segments_period_graph",
      "rms_annual_kpis",
      "rms_ytd_annual_kpis",
      "segmentschannels_segments"
    ],
    vs_items: ["otb", "stly", "bud", "ly"]
  }),
  methods: {
    getDimensionColor(index) {
      return Utils.getDimensionColor(this.segments, index);
    },
    getDimensionName(index) {
      return Utils.getDimensionName(this.segments, index);
    }
  },
  mounted() {},
  computed: {
    vss() {
      return this.hotelsdot.series_ui
        ? Object.values(this.hotelsdot.series_ui).filter(s => s.type === "vs")
        : null;
    },
    ...mapState({
      rms: state => state.rms,
      hotelsdot: state => state.hotelsdot,
      segments: state => state.segmentschannels.segments
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style scoped>
.v-list--dense .v-list-item {
  min-height: 33px;
}
</style>
