<template>
  <div>
    <v-row class="mx-0">
      <v-col class="kpi-cell spacer pa-0"> </v-col>
      <v-col class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col
            v-for="col of columns"
            :key="col.value"
            :class="classCol(col)"
            @mouseover="kpi_hover = col.value"
            @mouseleave="kpi_hover = ''"
          >
            <a-period-compare :periods="col.title" dense></a-period-compare>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";

export default {
  name: "a-dg-table-columns",
  components: {
    aPeriodCompare
  },
  props: {
    columns: Array
  },
  computed: {
    kpi_hover: {
      get() {
        return this.$store.state.rms.hotels_kpi_hover;
      },
      set(value) {
        this.$store.dispatch("setRmsHotelsKpiHover", value);
      }
    }
  },
  methods: {
    classCol(col) {
      let hide = false;
      let clas = "pa-0 kpi-cell kpi-cell-row align-center justify-center";
      if (this.kpi_hover == col.value) {
        clas += " kpi-hover";
      }
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (col.value == "pickup_1" || col.value == "pickup_2") {
          hide = true;
        }
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        if (col.value == "pickup_3") {
          hide = true;
        }
      }
      if (this.$vuetify.breakpoint.xs) {
        if (col.value == "pickup_4") {
          hide = true;
        }
      }
      if (hide) {
        clas += " d-none";
      } else {
        clas += " d-flex";
      }
      return clas;
    }
  }
};
</script>
<style lang="scss" scoped>
.spacer {
  min-width: 200px;
  max-width: 200px;
  z-index: 1;
}
.kpi-cell {
  height: $minHeightColRow;
  border-left: $borderType $borderColorV;
  border-right: $borderType $borderColorV;
  border-bottom: 3px solid #ffffff;
  display: block;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  width: 100%;

  &.kpi-hover {
    border-bottom: 3px solid #2d6ece;
  }
}
</style>
